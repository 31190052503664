import{Message} from  'element-ui'
import { countOccurrence } from "@/utils/function";
import { encryptCode,decryptCode,randomString,isValid,replaceBefore,isStartWithDafa } from "@/utils/function";
import { request_sign } from '@/utils/const';

import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
import API from '@/api/teacher/calendar/studentList';
export default {
  name: "StudentsList",
  components: {},
  inject:['reload'],
  data() 
  {
    return {
      search:'',
      
      openID:'',
      content: "",
      studentOptReductDialogVisible:false,
      studentOptListTitle:"",
      student_id:0,
      studentOptList:[],
      projectOptList:[],
      opt_des:"",
      opt_id:"",
      opt_score:"",
      opt_sign:"",
      projectOptListTitle:"",
      projectOptListDialogVisible:false,
      studentOptListDialogVisible:false,
      opt_content:"",
      opt_reduct_sign:0,
      opt_reduct_score:0,
      reductOKDialog:false,
      studentExpDataTitle:"",
      studentExpDataList:[],
      studentExpDataVisiable:false,

      project_id:"",
      startdate:"",
      enddate:"",
      projectList:[],
      studentslistData: [],
    };
  },
  created() 
  {
    this.getData();
  },
  methods: 
  {
    searchStudent()
    {
      if(this.project_id == "")
      {
        this.$Tips({
          // 消息提示内容
          message: "请选择项目！",
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 消失时间
          displayTime:1500
        });return;
      }
      if(this.startdate == "" || this.enddate == "")
      {
        this.$Tips({
          // 消息提示内容
          message: "请选择时间！",
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 消失时间
          displayTime:1500
        });return;
      }
      this.getData();
    },
    getData()
    {
      const self = this;
      let data = {
        "project_id":self.project_id,
        "startdate":self.startdate,
        "enddate":self.enddate,
      };
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      self.axios.post('/index.php/backother/getProjectHCTJ', data).then(result => {
        console.log(result)
        if (result.data.code > 0) 
        {
          self.projectList = result.data.projectList;
          // console.log(result.data.list)
          self.studentslistData = result.data.list;
        }
        else
        {
          self.$Tips({
            // 消息提示内容
            message: result.data.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 消失时间
            displayTime:1500
          })
        }
        // 关闭loading
        nprogressClose();
        self.$Loading.hide();
      });
    },

    getstudentslist() 
    {
      try 
      {
        let self = this;
        let data = {
          search:this.search
        }
        if(self.search == "")
        {
          self.$Tips({
            // 消息提示内容
            message: "请输入学生信息！",
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 消失时间
            displayTime: 1500
          });return;
        }
        nprogressStart();
        self.$Loading.show();
        self.axios
          .post("/index.php/backother/searchStudentScore",data)
          .then((result)=>{
            self.$Loading.hide();
            nprogressClose();
            // console.log(result)
            if(result.data.code > 0)
            {
              self.studentslistData = result.data.data;
            }
            else
            {
              self.$Tips({
                // 消息提示内容
                message: result.data.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 消失时间
                displayTime: 1500
              });return;
            }
        })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },
    goToExamRecordPage(row)
    {
      let str = row.name +'-'+row.personal_id;
      window.open("#/teacher/ExamsList?project_id="+row.project_id+"&name="+str+"&hideMenu=1&ids="+row.userid);
    },
    showOptRecord(project_id,student_id,name,personal_id)
    {
      let self = this;
      self.studentOptReductDialogVisible = true;
      self.studentOptListTitle = name+'-'+personal_id;
      self.project_id = project_id;
      self.student_id = student_id;
      self.getStudentOptRecord();
    },
    getStudentOptRecord()
    {
      let self = this;
      // 请求参数
      let str = encryptCode({
        id: self.project_id,
      })
      let data = { sign: request_sign, params: str }
      // startLoading();
      nprogressStart();
      self.$Loading.show();
      // 获取虚拟实验分类请求
      API.getHandleList(data)
          .then((result) => {
            nprogressClose();
            self.$Loading.hide();
            self.projectOptList = result.data;
          })
    },
    showUpdateOptDialog()
    {
      let self = this;
      // 打开弹窗
      self.projectOptListDialogVisible = true;
      self.projectOptListTitle = "设置";
      self.opt_des = '';
      self.opt_id = '';
      self.opt_score = '';
      self.opt_sign = '';
      // 请求操作列表数据
      self.getProjectOptList();
    },
    getProjectOptList()
    {
      let self = this;
      // 请求参数
      let str = encryptCode({
        id: self.project_id
      })
      let data = {sign:request_sign,params:str};
      nprogressStart();
      self.$Loading.show();
      API.getHandleList(data)
          .then((result) => {
              if (result.code > 0) 
              {
                nprogressClose();
                self.$Loading.hide();
                self.projectOptList = result.data;
              }
          });
    },
    showStudentOptDialog()
    {
      let self = this;
      // 请求参数
      let str = encryptCode({
        project_id: self.project_id,
        student_id: self.student_id,
      })
      let data = { sign: request_sign, params: str }
      nprogressStart();
      self.$Loading.show();
      API.showOptRecord(data)
          .then((result) => {
            nprogressClose();
            self.$Loading.hide();
            self.studentOptList = result.data;
            self.studentOptListDialogVisible = true;
          })
    },
    openStudentReductDialog(row)
    {
      var self = this;
      self.opt_id = row.id;
      self.opt_content = row.des;
      self.opt_reduct_sign = row.sign;
      self.opt_reduct_score = row.score;
      if(self.opt_reduct_sign == 0)
      {
        self.opt_reduct_score = row.score * -1;
      }
      self.reductOKDialog = true;
    },
    optUpdate()
    {
      let self = this;
      // 请求参数
      let str = encryptCode({
        id: self.opt_id,
        project_id: self.project_id,
        des: self.opt_des,
        score: self.opt_score,
        optSign: self.opt_sign,
      })
      let data = {sign:request_sign,params:str};
      nprogressStart();
      self.$Loading.show();
      API.updateHandle(data)
          .then((result) => {
              if (result.code > 0) 
              {
                self.getProjectOptList();
                self.opt_des = '';
                self.opt_id = '';
                self.opt_score = '';
                self.opt_sign = '';
              }
              nprogressClose();
              self.$Loading.hide();
          })
    },
    studentReductOK()
    {
      let self = this;
      let json = {
        opt_id: self.opt_id,
        project_id: self.project_id,
        score: self.opt_reduct_score,
        student_id:self.student_id
      };
      // console.log(json);return;
      // 请求参数
      let str = encryptCode(json);
      let data = {sign:request_sign,params:str};
      nprogressStart();
      self.$Loading.show();
      API.execDeductOpt(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.reductOKDialog = false;
            }
            nprogressClose();
            self.$Loading.hide();
          }).catch(() => {
            nprogressClose();
            self.$Loading.hide();
          })
    },
    getExpDataList(project_id,student_id,name,personal_id)
    {
      let self = this;
      self.student_id = student_id;
      self.project_id = project_id;
      let json = {
        project_id: project_id,
        student_id: student_id
      };
      self.studentExpDataTitle = name +' - '+personal_id;
      // 请求参数
      let str = encryptCode(json);
      let data = {sign:request_sign,params:str};
      nprogressStart();
      self.$Loading.show();
      API.getUserExpdataListByProjectid(data)
          .then((result) => {
            nprogressClose();
            self.$Loading.hide();
            self.studentExpDataVisiable = true;
            if (result.code > 0) 
            {
              self.studentExpDataList = result.data;
            }
          }).catch(() => {
            nprogressClose();
            self.$Loading.hide();
          })
    },
    openFlushExpdataScoreDialog()
    {
      let self = this;
      // 删除同事请求
      self.$confirm(`是否确认刷新虚拟实验成绩？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let json = {
          open_id: self.openID,
          user_id: self.student_id,
          project_id: self.project_id
        };
        console.log(json);
        let str = encryptCode(json);
        let data = {sign:request_sign,params:str};
        nprogressStart();
        self.$Loading.show();
        API.flushSigExpScore(data)
            .then((result) => {
                if (result.code > 0) 
                {
                    this.$Tips({
                        // 消息提示内容
                        message: result.msg,
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'success',
                        displayTime: 1500
                    })
                    nprogressClose();
                    self.$Loading.hide();
                    self.studentExpDataVisiable = false;
                    self.getstudentslist();
                }
            }).catch(() => {
              nprogressClose();
              self.$Loading.hide();
          });
      })
    },
    goReportPage(project_id, report_id, group_id, report_status,row) {
      var self = this;
      console.log(row)
      if (report_status == 4 || report_status == 5 || report_status == 3) 
      {
        window.open(
          "#/teacher/report/reportCorrection?project_id=" +
            project_id +
            "&report_id=" +
            report_id +
            "&group_id=" +
            group_id +
            "&open_id=" +
            row.open_id +
            "&name=" +
            row.project_name +
            "&type=" +
            1 +
            "&hideMenu=" +
            1
        );//*/
        /*window.sessionStorage.setItem('href',window.location.href);
        self.$router.push({
          path: "/teacher/report/reportCorrection",
          query: {
            project_id: project_id,
            report_id: report_id,
            group_id: group_id,
            open_id:self.$route.query.id,
            name:self.$route.query.name,
            type:1
          }
        }).then(()=>{
          self.reload()
        })//*/
      }
    },
    resetStudentOpt(row)
    {
      let self = this;
      // 请求参数
      let str = encryptCode({
        project_id: self.project_id,
        optRecord_id: row.id,
      })
      let data = {sign:request_sign,params:str}
      // 删除同事请求
      self.$confirm(`确认要撤销本条操作记录么?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        nprogressStart();
        self.$Loading.show();
        API.delOptRecord(data)
            .then((result) => {
                if (result.code > 0) 
                {
                  self.getstudentslist();
                  self.showStudentOptDialog();
                  this.$Tips({
                      // 消息提示内容
                      message: result.msg,
                      // 消息提示类型（success-成功,warning-警告/失败）
                      messageType: 'success',
                      displayTime: 1500
                  })
                }
            })
      }).catch(() => {
        nprogressClose();
        self.$Loading.hide();      
      });
    },
    
  },
};















