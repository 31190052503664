<template>
  <div class="studentsList">
    <!-- <div class="title">
      <div class="inlineBlock"><el-button class="backButton">返回</el-button></div>
      <div class="inlineBlock titleText">日程 》 项目名称</div>
    </div> -->
    <el-card>
      <div style="text-align:left;width:100%;height:60px;line-height:50px;margin-bottom: 0px;">
        <div class="searchStudent float_left" style="width: 360px;height: 40px;">
          <el-select v-model="project_id" placeholder="请选择项目" style="width: 340px;">
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="searchStudent float_left" style="width: 240px;height: 40px;">
          <el-date-picker
            v-model="startdate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择开始日期">
          </el-date-picker>
        </div>
        <div class="searchStudent float_left" style="width: 240px;height: 40px;">
          <el-date-picker
            v-model="enddate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择结束日期">
          </el-date-picker>
        </div>
        <div class="searchStudent float_left" style="width: 240px;height: 40px;">
          <el-button type="primary" @click="searchStudent()">查询</el-button>
        </div>
      </div>
      <!-- 学生列表表格 -->
      <el-table header-row-class-name="el-customHead" :data="studentslistData" stripe style="width: 100%" >
        <el-table-column prop="name" label="消耗品" > </el-table-column>
        <el-table-column prop="num" label="数量" ></el-table-column>
        <el-table-column prop="unit" label="耗材单位" ></el-table-column>
        <el-table-column prop="studentNum" label="学生人数" ></el-table-column>
        <el-table-column prop="totalXHL" label="消耗量"></el-table-column>
        <el-table-column prop="unit" label="耗材单位"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
// 引入学生列表表格组件
import StudentsList from "@/assets/js/teacher/other/HCTJ.js";
export default {
  ...StudentsList
}
</script>
<style lang="scss">
.el-table {
  padding-left: 0px !important;
}
.expdatalist{
  .el-table {
    height: 57vh !important;
  }
  .el-dialog__body {
    padding: 17px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
}

.studentsList {
  .el-dialog {
    width: 27.5rem !important;
    height: 30rem !important;
    border-radius: 12px !important;
  }
  .el-dialog__title {
    font: normal normal normal 1.125rem Source Han Sans CN;
  }
  .el-dialog__headerbtn {
    font-size: 1.4375rem;
  }
  .opt-dialog{
    .el-dialog{
      width: 62.5rem !important;
      height: 40rem !important;
      .el-table{
        height: 50vh !important;
      }
    }
  }
  .opt-reduct-dialog{
    .el-dialog{
      width: 50rem !important;
      height: auto !important;
    }
    .dialog-footer{
      font-size: 16px;
    }
  }
}
.opt-title{
  position: absolute;
  top: 22px;
  right: 140px;cursor:pointer;color:#0D5570;font-size:16px
}

.studentsList .sendNoticeDialog .el-dialog {
  width: 47rem !important;
  height: 20rem !important;
}

.studentsList .addStudentDialog .el-dialog {
  width: 47rem !important;
  height: auto !important;
}

.studentsList .delStudentDialog .el-dialog,
.studentsList .delmanyStudentDialog .el-dialog,
.studentsList .allSignStudentDialog .el-dialog,
.studentsList .allOutStudentDialog .el-dialog {
  width: 27.5rem !important;
  height: 13.5rem !important;
}

.studentsList .addStudentDialog .el-dialog__body {
  padding: 25px 20px;
}

.studentsList .delStudentDialog .el-dialog__body,
.studentsList .delmanyStudentDialog .el-dialog__body .studentsList .allSignStudentDialog .el-dialog__body,
.studentsList .allOutStudentDialog .el-dialog__body {
  padding-top: 1.875rem !important;
  height: 5.625rem;
  /* padding: 30px 20px; */
  color: #606266;
  font-size: .875rem;
  word-break: break-all;
}

.el-customHead .el-table_1_column_1,
.el-customHead .el-table_1_column_2,
.el-customHead .el-table_1_column_3,
.el-customHead .el-table_1_column_4,
.el-customHead .el-table_1_column_5,
.el-customHead .el-table_1_column_6,
.el-customHead .el-table_1_column_7,
.el-customHead .el-table_1_column_8,
.el-customHead .el-table_1_column_9,
.el-customHead .el-table_1_column_10,
.el-customHead .el-table_1_column_11 {
  background-color: #F0EFF4 !important;
}

.el-customHead .el-table_1_column_1::after {
  position: absolute;
  left: 1.975rem;
  top: 0.9rem;
}

.studentsList .searchStudent .el-input__inner {
  //height: 100% !important;
  line-height: 100% !important;
}

.studentsList .searchStudent .el-input {
  height: 100%;
  float: left;
}

.studentsList .searchStudent {
  width: 12.6042vw;
  height: 2.0833vw;
  margin-left: .5vw;
}

.studentsList .addStudentDialog .el-table {
  max-height: 16.625rem;
  overflow: auto;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0D5570;
  border-color: #0D5570;
}

.studentsList .el-tabs__item.is-active {
  color: #0D5570;
}

.studentsList .el-tabs__active-bar {
  background-color: #0D5570;
}

.cell {
  -webkit-user-select: text;
}
</style>
<style lang='scss' scoped >
@import "@/assets/css/teacher/calendar/StudentsList.scss";
</style>